.settings-nav .card {
  cursor: pointer;
}

.settings-row {
  display: grid;
  grid-template-columns: 22px 1fr 8px;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  padding: 0.8em 1em;
  background: var(--note-bg);
  border-radius: 10px;
  cursor: pointer;
  gap: 10px;
}

.settings-row:hover {
  color: var(--highlight);
}

.settings-row + .settings-row {
  margin-top: 10px;
}

.align-end {
  margin-left: auto;
}

.settings-row svg {
  width: 100%;
  height: 100%;
}
