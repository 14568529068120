.profile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.profile .banner {
  width: 100%;
  height: 160px;
  object-fit: cover;
  margin-bottom: -60px;
  z-index: 0;
}
@media (min-width: 720px) {
  .profile .banner {
    border-radius: 12px;
  }
}

.profile .profile-actions {
  position: absolute;
  top: 72px;
  right: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.profile .icon-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.profile .profile-actions button:not(:last-child) {
  margin-right: 8px;
}

.profile .profile-actions button.icon:not(:last-child) {
  margin-right: 0;
}

@media (min-width: 520px) {
  .profile .banner {
    width: 100%;
    max-width: 720px;
    height: 280px;
  }
  .profile .profile-actions button.icon:not(:last-child) {
    margin-right: 2px;
  }
}

.profile .profile-wrapper {
  margin: 0 16px;
  width: calc(100% - 32px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  overflow: hidden;
}

.profile p {
  white-space: pre-wrap;
}

.details-wrapper > .name > h2 {
  margin: 12px 0 0 0;
  font-weight: 600;
  font-size: 19px;
  line-height: 23px;
}

.profile .nip05 {
  display: flex;
  font-size: 16px;
}

.profile-wrapper > .avatar-wrapper {
  z-index: 1;
}

.profile-wrapper > .avatar-wrapper .avatar {
  width: 120px;
  height: 120px;
  background-image: var(--img-url);
  border: 3px solid var(--bg-color);
}

.profile .name {
  display: flex;
  flex-direction: column;
}

.profile .details {
  width: 100%;
  color: var(--font-secondary-color);
  margin-bottom: 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}

.profile .details p {
  word-break: break-word;
}

.profile .details a {
  color: var(--highlight);
  text-decoration: none;
}

.profile .details a:hover {
  text-decoration: underline;
}

.profile .btn-icon {
  color: var(--font-color);
  padding: 6px;
}

.profile .details-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: calc(100% - 32px);
}

.profile .details .text {
  font-size: 14px;
}

.profile .links {
  font-size: 14px;
  margin-top: 4px;
  margin-left: 2px;
  margin-bottom: 12px;
}

.profile .website {
  margin: 4px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}

@media (max-width: 720px) {
  .profile .lnurl {
    display: none;
  }
}

.profile .website a {
  color: var(--font-color);
}

.profile .website a {
  text-decoration: none;
}

.profile .website a:hover {
  text-decoration: underline;
}

.profile .lnurl {
  cursor: pointer;
}

.profile .ln-address {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.profile .lnurl:hover {
  text-decoration: underline;
}

.profile .lnurl {
  overflow: hidden;
  text-overflow: ellipsis;
}

.profile .links svg {
  color: var(--highlight);
  margin-right: 8px;
  width: 12px;
  height: 12px;
}

.profile .npub {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.profile .copy {
  margin-top: 12px;
}

.qr-modal .modal-body {
  width: unset;
  margin-top: -120px;
}

.qr-modal .pfp {
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.qr-modal .pfp .avatar {
  width: 48px;
  height: 48px;
}

.qr-modal .pfp .avatar-wrapper {
  margin: 0 0 8px 0;
}

.qr-modal .pfp .avatar-wrapper .avatar {
  border: none;
}

.qr-modal .pfp .username {
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.qr-modal canvas {
  border-radius: 10px;
}

.qr-modal .pfp .display-name {
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.profile .zap-amount {
  font-weight: normal;
  margin-left: 4px;
}

.zaps-total {
  text-align: right;
  padding-right: 12px;
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 24px;
}

.profile .nip05 .nick {
  display: unset;
}
.profile .nip05 .domain {
  display: unset;
}

.badge-card .badge-icon {
  width: 48px;
  height: 48px;
  margin-right: 0.3em;
}

.badge-card .header {
  align-items: center;
  flex-direction: row;
}

.badge-card .body {
  margin-bottom: 0;
}
